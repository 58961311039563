import Vue from 'vue';

/**
 * Class that resolve dynamic components and insert them into the DOM.
 * @author Caio Silveira
 */
export default class ComponentResolver {
  /**
   * Retrieves an instance of any Vue component.
   * @param {Object} component Component reference.
   * @param {Object} propsData Component props.
   */
  static getInstanceOf(component, propsData) {
    const ComponentClass = Vue.extend(component);
    const instance = new ComponentClass({ propsData });
    return instance.$mount();
  }
}
