<template>
  <div :class="wrapper">
    <template>
      <hero
        :heroImgSrc="cloudinary.GOVERNMENT_ILLUSTRATION"
        :heroTitle="$t('governmentTitle')"
        :heroDescrip="$t('governmentTitleDes')"
      />
      <solution-card
        :titleText="$t('governmentIllustration')"
        v-bind:subDetails="$t('governmentIllustrationDes')"
        v-bind:imageUrl="cloudinary.GOVERNMENT_TEST_RESULTS"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <solution-card
        :titleText="$t('governmentImmunization')"
        v-bind:subDetails="$t('governmentImmunizationDes')"
        v-bind:imageUrl="cloudinary.GOVERNMENT_IMMUNIZATION_RECORDS"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <solution-card
        :titleText="$t('governmentTracing')"
        v-bind:subDetails="$t('governmentTracingDes')"
        v-bind:imageUrl="cloudinary.GOVERNMENT_CONTACT_TRACING"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <solution-card
        :titleText="$t('governemntVaccine')"
        v-bind:subDetails="$t('governemntVaccineDes')"
        v-bind:imageUrl="cloudinary.GOVERNMENT_VACCINE_APPOINTMENT"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <solution-card
        :titleText="$t('governmentResident')"
        v-bind:subDetails="$t('governmentResidentDes')"
        v-bind:imageUrl="cloudinary.GOVERNMENT_RESIDENT_COMMS"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <section class="section">
        <case-management-card />
      </section>

      <section class="section">
        <figure
          class="quote-wrapper"
          :style="{
            '--quoteUrl': getUrlValue(cloudinary.GOVERNMENT_QUOTE_ICON),
          }"
        >
          <blockquote>
            <p class="quote-text">
              {{ $t('governmentUtahDepartment1') }}
            </p>
            <p class="quote-text">
              {{ $t('governmentUtahDepartment2') }}
            </p>
          </blockquote>
          <figcaption>
            <strong>{{ $t('governmentUtahDepartmentSign') }}</strong>
          </figcaption>
        </figure>
      </section>
      <section class="section last">
        <div class="seal split">
          <div class="split-img">
            <img :src="cloudinary.GOVERNMENT_SEAL_OF_UTAH" class="seal-img" />
          </div>
          <div class="split-text">
            <figure
              class="quote-wrapper"
              :style="{
                '--quoteUrl': getUrlValue(cloudinary.GOVERNMENT_QUOTE_ICON),
              }"
            >
              <blockquote>
                <p class="quote-text">
                  {{ $t('governmentSeal') }}
                </p>
              </blockquote>
              <figcaption>
                <img :src="cloudinary.GOVERNMENT_GOVT_SIGNED" width="200" />
                <p class="quote-text">
                  <strong>{{ $t('governmentSealSign') }}</strong>
                </p>
              </figcaption>
              <btn
                :text="$t('readLetter')"
                @btn-click="open"
                color="primary"
                customClass="button-link"
              />
            </figure>
          </div>
        </div>
      </section>
    </template>
    <div class="mobile">
      <AppStoreLinks />
    </div>
    <modal
      v-if="showModal"
      @on-close="closeModal"
      :component="componentToResolve"
      transitionStyle="slide"
      closeButtonStyle="button"
      overlayColor="#fff"
    />
  </div>
</template>

<script>
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';
import Btn from '@/components/basic/Btn';
import cloudinary from '@/constants/cloudinary';
import Hero from '@/components/basic/Hero';
import MessageModalContent from '@/views/MessageModalContent';
import Modal from '@/components/structure/Modal.vue';
import SolutionCard from '@/views/SolutionCard.vue';
import CaseManagementCard from '@/views/CaseManagementCard.vue';

export default {
  name: 'Government',
  components: {
    AppStoreLinks,
    Btn,
    CaseManagementCard,
    Hero,
    Modal,
    SolutionCard,
  },
  data() {
    return {
      title: 'Government',
      wrapper: 'Government_component_wrapper',
      showModal: false,
    };
  },
  methods: {
    getUrlValue(url) {
      return `url(${url})`;
    },
    open() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  computed: {
    componentToResolve() {
      return MessageModalContent;
    },
  },
  created() {
    this.cloudinary = cloudinary;
  },
};
</script>

<style scoped lang="scss">
@import '../styles/global/marketing.scss';

.section {
  @extend %section;
}

.section.last {
  @extend %section-last;
}

.seal {
  min-height: 30rem;
  padding: 4rem;
  border-radius: 32px;
  background-color: rgba(92, 138, 254, 0.08);

  .split-img {
    text-align: center;

    @include bp-xlarge {
      width: 15rem;
      margin-right: 5rem;
    }
  }

  .split-text {
    @include bp-xlarge {
      width: 60%;
    }
  }

  .seal-img {
    max-width: 15rem;
    width: 100%;
    display: inline-block;
  }

  figcaption {
    margin-bottom: 1.5rem;
  }
}

.quote-text {
  order: 3;
  font-size: 1.375rem;
  line-height: 1.7rem;
  @include bp-xsmall {
    font-size: 1.375rem;
  }
}

.quote-wrapper {
  position: relative;

  blockquote {
    margin: 0;

    p {
      margin-bottom: 1.5rem;
    }
  }

  figcaption {
    margin-top: 0.625rem;
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 700;
  }

  &::before {
    content: '';
    width: 2rem;
    height: 2.6rem;
    display: block;
    margin-left: -0.5rem;
    background-image: var(--quoteUrl);
    background-repeat: no-repeat;
  }
}

.split {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include bp-xlarge {
    flex-direction: row;
  }
}

.mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -2px 4px rgba(8, 35, 48, 0.1);
  @include bp-medium {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;
  }
  @include bp-mediumsmall {
    display: none;
  }
}
</style>
