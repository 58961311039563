<template>
  <transition :name="getTransitionStyle" appear>
    <div
      class="modal-overlay"
      id="modal"
      :style="{ '--bg-color': overlayColor }"
    >
      <div class="modal">
        <div class="modal-header" v-if="showHeader">
          <h3 class="modal-title">{{ title }}</h3>
          <a class="modal-close" @click="close" v-if="showDefaultClose"></a>
        </div>
        <div class="modal-content">
          <div class="component-wrapper" ref="dynamic" v-if="component"></div>
          <slot v-else></slot>
        </div>
        <div class="modal-footer" v-if="showBottomClose">
          <btn
            :text="$t('close')"
            color="primary"
            customClass="button-link"
            @btn-click="close"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Resolver from '@/services/ComponentResolver';
import Btn from '@/components/basic/Btn';

export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
    component: {
      type: Object,
    },
    componentProps: {
      type: Object,
      default() {
        return {};
      },
    },
    transitionStyle: {
      type: String,
      default() {
        return 'fade';
      },
    },
    closeButtonStyle: {
      type: String,
      default() {
        return 'default';
      },
    },
    overlayColor: {
      type: String,
      default() {
        return 'rgba(0, 0, 0, 0.9)';
      },
    },
  },
  components: {
    Btn,
  },
  computed: {
    getTransitionStyle() {
      return this.transitionStyle;
    },
    showBottomClose() {
      return this.closeButtonStyle === 'button';
    },
    showDefaultClose() {
      return this.closeButtonStyle === 'default';
    },
    showHeader() {
      return this.showDefaultClose || this.title;
    },
  },
  mounted() {
    this.setRootToBody();
    if (this.component) {
      const componentInstance = Resolver.getInstanceOf(this.component, {
        ...this.componentProps,
        ...this.$route.params,
      });
      this.attachSlot(componentInstance);
    }
  },
  methods: {
    setRootToBody() {
      let body = document.getElementsByTagName('body')[0];
      let appContainer = document.getElementById('app');
      body.classList.add('modal-opened');
      body.insertBefore(this.$el, appContainer);
    },
    attachSlot(slotComponent) {
      this.$refs.dynamic.appendChild(slotComponent.$el);
    },
    close() {
      this.$emit('on-close');
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('modal-opened');
    },
  },
  onDestroy() {
    this.close();
  },
};
</script>

<style scoped lang="scss">
#modal {
  position: fixed;
  background: var(--bg-color);
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @include bp-xsmall {
    padding: 1.25rem 1rem;
  }
  @include bp-small {
    padding: 1.25rem 1rem;
  }
  @include bp-medium {
    padding: 1rem 3rem;
  }
  @include bp-large {
    padding: 1rem 4rem;
  }
}
.component-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
}
.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.modal-header {
  display: flex;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
}
.modal-footer {
  position: relative;
}
.modal-title {
  color: white;
  text-transform: uppercase;
  flex-grow: 1;
}
.modal-close {
  right: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=);
  background-size: 24px;
  background-repeat: no-repeat;
  opacity: 0.8;
  cursor: pointer;
}
.modal-content {
  overflow: auto;
  flex-grow: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity 0.5s;
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}

// slide effect styles
.slide-enter,
.slide-leave-to {
  transform: translateY(100vh);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1s;
}

// bottom close button styles
.button-link {
  position: absolute;
  bottom: 4rem;
  z-index: 100;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
</style>
