<template>
  <div class="letter-wrapper">
    <img
      :src="cloudinary.GOVERNMENT_SEAL_OF_UTAH"
      class="seal-img"
      alt="Seal of Utah"
    />
    <h2 class="title" v-if="getLetterTitle">{{ getLetterTitle }}</h2>
    <div
      class="wysiwyg"
      v-html="getLetterMessage"
      v-if="getLetterMessage"
    ></div>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';
import { butter } from '@/buttercms';

export default {
  data() {
    return {
      thisPage: {
        official_message: [],
      },
    };
  },
  props: {
    locale: {
      type: String,
      default() {
        return 'en';
      },
    },
  },
  computed: {
    getLetterTitle() {
      return this.thisPage.official_message[0]?.message_title || '';
    },
    getLetterMessage() {
      return this.thisPage.official_message[0]?.message || '';
    },
  },
  created() {
    this.fetchCms();
    this.cloudinary = cloudinary;
  },
  methods: {
    fetchCms() {
      const locale = this.locale;
      butter.content
        .retrieve(['official_message'], { locale: locale })
        .then(resp => {
          this.thisPage = resp.data.data;
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.letter-wrapper {
  max-width: 60rem;
}

.seal-img {
  width: 11.25rem;
  margin: 3rem auto 1.5rem;
  display: block;
}

.title {
  font-size: 2rem;
  line-height: 2.6rem;
  text-align: center;
  margin-bottom: 1rem;
}

.wysiwyg {
  color: #505050;
  font-size: 1rem;
  line-height: 1.5rem;
}
</style>
